<template>
  <div class="year-drop">
    <div class="year-drop-items" v-for="(item,index) in years" :key="index">
      <p @click="sendYear(item.year)">{{item.year}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["years"],
  data() {
    return {};
  },
  methods: {
    sendYear(data) {
      this.$emit("yearItem", data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import  "./YearDropdown.scss"
</style>